<template>
  <div class="app">
    <div class="protocol">
      <div class="protocolHead"> 注册协议</div>
      <div class="protocolContent">
          xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx11111111111111111111111111111111111111111
      </div>

      <van-button-fix :van-button-str="vanButtonStr"></van-button-fix>

    </div>
  </div>
</template>

<script>

import {reactive, toRefs} from 'vue'
import VanButtonFix from "../components/common/vanButtonFixed";


export default {
  name: 'RegisterProtocol',
  components: { VanButtonFix },

  setup() {
    const data = reactive({

      vanButtonStr:"我已知晓"
        },
    )

    const methodsMap = {
      selectRegister: () => {
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.app {
  .protocol{
    &Head{
      font-weight: 900;
      text-align: center;
      margin-top: 20px;
    }
    &Content{
      font-size: 16px;
      word-break: break-all;
      word-wrap: break-word;
      line-height: 20px;
      text-indent:20px;
      padding: 15px;
    }
  }

}
</style>
