<!--
 * @Descripttion: 
 * @Author: ''
 * @Date: 2021-03-08 17:39:39
 * @LastEditTime: 2021-04-03 18:41:01
-->
<template>
  <div class="vanButton">
      <van-button class="vanButtonFix" round block type="primary" @onclick="onClickBack" >
        {{vanButtonStr}}
      </van-button>
  </div>
</template>

<script>
import { reactive,toRefs} from 'vue'
export default {
  name: 'vanButtonFix',
  components: {

  },
  props:{
    vanButtonStr:{
      type:String,
      default:()=>{
        return "返回"
      }
    },
  },
  setup(){


    let data = reactive({
    });

    let methodMap = {


      // 右边返回
      onClickBack:()=>{
          this.$router.back();
      }
    }


    
    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less" scoped>
.vanButton{

  .vanButtonFix{
    position: fixed;
    height: 30px;
    bottom: 20px;
    width: 50%;
    left: 0;
    right: 0;
    margin:0 auto;
  }
}

</style>